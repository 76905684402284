import {makeAutoObservable} from "mobx";
import IFile from "../Types/FileTypes";

class Gallery {

    list: IFile[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    getList(): IFile[]
    {
        return this.list;
    }

    setList(list:IFile[]): void {
        this.list = list;
    }

}

const gallery = new Gallery();
export default gallery;
