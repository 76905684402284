import React, {useEffect, useState} from "react";
import Input from "./Input";
import Button from "./Button";
import ajax, {ajaxXMLCallback} from "../Classes/Ajax";
import security from "../store/security";
import Progress from "./Progress";
import styles from "./Upload.module.css";
import {v4 as uuid} from "uuid";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import FileList from "./FileList";
import fileList from "./FileList";
dayjs.extend(relativeTime);

interface IUploadProps {
}

const Upload = (props: IUploadProps) => {

    const baseURL = process.env.REACT_APP_HOST_URL;
    const apiUrl = '/api/files'

    const [files, setFiles] = useState<FileList|undefined>();
    const [progress, setProgress] = useState<number>(-1);

    const [totalTasks, setTotalTasks] = useState<number>(0);
    const [filesList, setFilesList] = useState<Array<File>>([]);
    const [currentTask, setCurrentTask] = useState<number>(1);
    const [upload, setUpload] = useState<boolean>(false);

    const [xhr, setXhr] = useState<XMLHttpRequest>();

    function onFileChange(e: any){
        setFiles(e.target.files)
        setTotalTasks(e.target.files.length);
        setFilesList(Array.from(e.target.files));
    }

    useEffect(() => {
        if(upload && currentTask <= totalTasks && currentTask !== 1) {
            uploadFile();
        }
    }, [currentTask]);

    function onButtonClick(e: React.MouseEvent<HTMLButtonElement>): void {
        uploadFile();
    }

    function uploadFile():void {
        setUpload(false);
        if(files) {
            const ajaxCallback: ajaxXMLCallback = {
                callback:(e: ProgressEvent<XMLHttpRequestEventTarget>) => {
                    if(e.lengthComputable) {
                        const p:number = (e.loaded / e.total) * 100;
                        if (Math.floor(p) !== progress) {
                            setProgress(Math.floor(p));
                        }
                        if(p === 100 ) {
                            filesList.shift();
                            setUpload(true);
                        }
                        // console.log(p, progress, (e.loaded / e.total) * 100);
                    }
                },
            }

            const xhrLocal:XMLHttpRequest = ajax.uploadFile(
                files[currentTask - 1],
                security.getJwt(),
                baseURL+apiUrl,
                ajaxCallback
            )
            setXhr(xhrLocal);

        }
    }

    function onTaskComplete(value: number) {
        if (value < totalTasks && progress === 100) {
            setCurrentTask((prev) => prev + 1);
            setProgress(0);
        }
    }

    function afterFileCanceled(taskNumber: number): void {
        if(taskNumber < totalTasks) {
            const list = filesList.filter((_, i) => i !== taskNumber - 1);
            setUpload(true);
            setFilesList(list);
            setCurrentTask(taskNumber + 1);
            setProgress(0);
        } else{
            setUpload(false);
            setFilesList([]);
            setCurrentTask(1);
            setProgress(-1)
        }
        setXhr(undefined);
    }


    useEffect(() => {
        if(totalTasks === 1 && progress === 100) {
            setTimeout(()=>{
                setProgress(-1);
            }, 1000);
        }
    }, [progress, totalTasks]);

    useEffect(() => {
        if(currentTask === totalTasks && progress === 100) {
            setTimeout(()=>{
                setCurrentTask(1);
                setProgress(-1);
            }, 2000);

        }
    }, [currentTask, progress]);


    return (
        <>
            <div className={styles.container}>
                <Input
                    type="file"
                    onChange={onFileChange}
                    label={'Browse'}
                />
                <Button text={'Send'} onClick={onButtonClick}/>

                <Progress
                     value={progress}
                     currentTask={currentTask}
                     totalTasks={totalTasks}
                     onTaskComplete={onTaskComplete}
                 />
            </div>

            {files && <FileList
                xhr={xhr}
                currentTask={currentTask}
                items={filesList}
                afterCanceled={afterFileCanceled}
            />}

        </>
    );
}

export default Upload;
