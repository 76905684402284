import React, {useEffect, useState} from 'react';
import IFile, {IFileExtension, IThumbnail} from "../../Types/FileTypes";
import styles from "./GalleryItem.module.css";
import ajax, {ajaxFileCallback} from "../../Classes/Ajax";
import security from "../../store/security";
import GalleryModal from "./GalleryModal";

interface IGalleryItemProps {
    thumbnail: IThumbnail,
    list: Array<IFile>,
}

const GalleryItem = (props:IGalleryItemProps) => {

    const [fetchThumbnail, setFetchThumbnail] = useState(false);
    const [thumbnail, setThumbnail] = useState<IThumbnail|null>(null);
    const [file, setFile] = useState<IFile|null>(null);
    const [width, setWidth] = useState<number>(512);
    const [url, setUrl] = useState<string|null>(null);
    const [openModal, setOpenModal] = useState(false);

    const onModalClose = () => {
        setOpenModal(false);
    }

    const onImageClick = (e: React.MouseEvent<HTMLDivElement>, thumbnail: IThumbnail): void => {
        setThumbnail(thumbnail);
        setFetchThumbnail(true);
        props.list.forEach((f: IFile) => {
           f.thumbnails.forEach((t: IThumbnail) => {
              if(t.id === thumbnail.id){
                  setFile(f);
              }
           });
        });
    }

    useEffect(() => {
        if(fetchThumbnail && thumbnail && file){
            setFetchThumbnail(false);
            const ajaxFileCallback: ajaxFileCallback = {
                callback: (res: Blob, file:IFile) => {
                    setUrl(URL.createObjectURL(res));
                    setOpenModal(true);
                }
            }
            ajaxFileCallback.file = file;
            console.log(file);
            const thumbnailToFetch = file.thumbnails.find((t: IThumbnail) => t.width === width);
            if(thumbnailToFetch){
                ajax.makeFileRequest(`/api/thumbnail/${thumbnailToFetch.id}`, security.getJwt(), ajaxFileCallback);
            }

        }
    }, [fetchThumbnail, thumbnail, file, width]);

    return (<>
        <div className={styles.div} onClick={(e: React.MouseEvent<HTMLDivElement>)=>onImageClick(e, props.thumbnail)}>
            <img src={props.thumbnail.url} alt={'icon'}/>
        </div>
        {(url && thumbnail && openModal) &&
            <GalleryModal
                url={url}
                thumbnail={thumbnail}
                onModalClose={onModalClose}
            />}
    </>)
}

export default GalleryItem;
