import React from "react";
import icon from './Icon.module.css';
import {Link} from "react-router-dom";

interface IconProps {
    imagePath?:string;
    size?:number;
    label?:string;
    path?: string;
}

const Icon = (props:IconProps) =>{
    let size:number|undefined = props.size;
    if(size && size < 60) {
        size = 60;
    }
    return (
        <Link to={props.path ? props.path : '#'}>
            <div
                style={props.size && size ? {width: `${size}px`, height: `${!props.label ? size+'px': (size+(size*0.25))}px`} : {width: '128px'}}
                className={icon.iconWrapper + `${!props.label ? ' '+icon.onlyIcon : ' '}`}
            >
                <img
                    className={icon.icon}
                    src={props.imagePath}
                    alt={props.imagePath}
                    width={size ? size - 40 : 128 - 40}
                    height={size ? size - 40 : 128 - 40}
                />
                {props.label ? <div className={icon.label}>{props.label.toUpperCase()}</div> : null}
            </div>

        </Link>

    )
}

export default Icon;
