import React, {useEffect, useRef, useState} from "react";
import GalleryItem from "../components/gallery/GalleryItem";
import ajax, {ajaxCallback, ajaxFileCallback} from "../Classes/Ajax";
import security from "../store/security";
import IFile, {IThumbnail} from "../Types/FileTypes";
import gallery from "../store/gallery";
import Icon from "../components/Icon";
import styles from "./GalleryView.module.css";

const GalleryView = () => {

    const [size, setSize] = useState<number>(64);
    const [list, setList] = useState<IFile[]>([]);
    const [thumbnails, setThumbnails] = useState<IThumbnail[]>([]);
    const [thumbnailsIds, setThumbnailsIds] = useState<number[]>([]);
    const done = useRef(false);
    const [index, setIndex] = useState<number>(0);

    const onFileListCallback:ajaxCallback = {
        callback: (res: any) => {
            const files = res.data.files as unknown as IFile[];
            gallery.setList(files);
            setList(files);
        }
    }

    const onThumbnailGetCallback: ajaxFileCallback = {
        callback: (res: Blob, file: IFile) => {
            const url = URL.createObjectURL(res);
            console.log(file.id);
            file.thumbnails.forEach((thumb: IThumbnail) => {
                if(thumb.width === size){
                    const thumbnail:IThumbnail = thumb;
                    thumbnail.url = url;
                    const alreadyIn = thumbnails.filter((t: IThumbnail) => t.id === thumbnail.id);
                    if(alreadyIn.length === 0){
                        setThumbnails(items=> [...items, thumbnail]);
                    }
                }
            });
        },
    }

    useEffect(() => {
        setList([]);
        gallery.setList([]);
        ajax.makeRequest('/api/files', security.getJwt(), onFileListCallback);
    }, []);

    useEffect(() => {
        if(list.length > 0) {
            list.forEach((file: IFile) => {
                file.thumbnails.forEach((thumbnail: IThumbnail) => {
                    if(thumbnail.width === size){
                        const alreadyIn = thumbnailsIds.filter((el:number) => el === thumbnail.id);
                        if(alreadyIn.length === 0){
                            setThumbnailsIds((prev) => [...prev, thumbnail.id]);
                        }
                    }
                });
            });
            done.current = true;
        }
    }, [list]);

    const getFile = (l: IFile[], i: number):IFile|null => {
        let file: IFile|null = null;
        l.forEach((f: IFile) => {
            f.thumbnails.forEach((thumbnail: IThumbnail) => {
                if(thumbnail.id == thumbnailsIds[i]){
                    file = f;
                }
            });
        });
        return file;
    }

    const fetchThumbnail = (idx: number) => {
        if(idx < thumbnailsIds.length) {
            // todo find file basing on thumbnail id
            const file = getFile(list, 0);
            if(file){
                onThumbnailGetCallback.file = file;
            }
            ajax.makeFileRequest(`/api/thumbnail/${thumbnailsIds[idx]}`, security.getJwt(), onThumbnailGetCallback);
        }
    }

    useEffect(() => {
        if(thumbnails.length > 0) {
            setIndex(prev => prev + 1);
        }
    }, [thumbnails]);


    useEffect(() => {
        if(index > 0) {
            const file = getFile(list, index);
            if(file){
                onThumbnailGetCallback.file = file;
                ajax.makeFileRequest(`/api/thumbnail/${thumbnailsIds[index]}`, security.getJwt(), onThumbnailGetCallback);
            }
        }
    }, [index]);

    useEffect(() => {
        if(done.current){
            fetchThumbnail(index);
        }
    }, [thumbnailsIds, done]);

    return (<div className="main-container">
            <div>
                <div>
                    <Icon
                        imagePath={'./svg/leftArrow.svg'}
                        path={'/'}
                        size={60}
                    />
                </div>
                <div className={styles.div}>
                    {thumbnails.map((thumbnail: IThumbnail, idx: number) => (
                        <GalleryItem
                            key={idx}
                            thumbnail={thumbnail}
                            list={list}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default GalleryView;
