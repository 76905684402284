import React from 'react';
import {IFileExtension} from "../Types/FileTypes";
import styles from "./Image.module.css";

interface FileImageProps {
    src: string,
    size: number,
}

const Image = (props: FileImageProps) => {
    return (
        <>
            <img
                src={props.src}
                height={props.size}
                alt={'image'}
                className={styles.img}
            />
        </>
    );
}

export default Image;
