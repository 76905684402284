import React, {useEffect} from "react";
import './../App.css';
import Upload from "../components/Upload";
import security from "../store/security";
import {NavigateFunction, useNavigate} from "react-router";
import Icon from "../components/Icon";

const UploadView = () => {
    const navigate: NavigateFunction = useNavigate();
    useEffect(() => {
        if(security.getJwt() === '') {
            navigate('/');
        }
    }, [security.getJwt()]);
    return (
        <>
            <Icon
                imagePath={'./svg/leftArrow.svg'}
                path={'/'}
                size={60}
            />
            <div className="App">
                <Upload/>
            </div>
        </>
    );
}

export default UploadView;
