import React, {MouseEventHandler} from 'react';
import styles from './Button.module.css';

interface ButtonProps {
    text: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = (props: ButtonProps) => {

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(props.onClick) {
            props.onClick(event);
        }
    }
    return (
        <>
            <button
                className={styles.button}
                onClick={onClick}
            >
                {props.text}
            </button>
        </>
    );
}

export default Button;
