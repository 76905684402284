import React from 'react';
import {useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import styles from './Input.module.css';

interface InputProps {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (value: React.MouseEvent<HTMLInputElement>) => void;
    dataAttr?: Array<InputDataAttribute>;
    inputClasses?: Array<string>;
    label?: string;
    labelClasses?: Array<string>;
    wrapperClasses?: Array<string>;
    type?:string;
}

type InputDataAttribute = {
    key:string;
    value: string;
}

const Input = (props: InputProps) => {
    const [value, setValue] = useState('');
    const [click, setClick] = useState(null);
    const uuid = uuidv4();

    function onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        if (props.onChange) {
            setValue(e.target.value);
            props.onChange(e);
        }
    }

    function onButtonClick(e: React.MouseEvent<HTMLInputElement>):void {
        if(!props.type || props.type === 'text') return;
        if(props.onClick){
            props.onClick(e);
        }
    }

    return (
        <div className={props.wrapperClasses ? props.wrapperClasses.join(' ') : (props.type !== "file" ? styles.input :styles.inputFile)}>
            {props.label && props.type === 'file' ? (
                <label
                    htmlFor={uuid}
                    className={props.labelClasses ? props.labelClasses.join(' ') : styles.inputButton}
                >{props.label}</label>
            ) : null}
            {props.label && !props.onClick && props.type !== 'file' ? (
                <label
                    htmlFor={uuid}
                    className={props.labelClasses ? props.labelClasses.join(' ') : ''}
                >{props.label}</label>
            ) : null}
            <input
                id={uuid}
                onChange={onInputChange}
                {...(props.onClick && props.label ? {value: props.label} : {value: value})}
                {...(props.dataAttr ? props.dataAttr.reduce((acc: Record<string, string>, attribute: InputDataAttribute) => {
                    acc[`data-${attribute.key}`] = attribute.value;
                    return acc;
                }, {}) : {})}
                className={props.inputClasses && props.type !== 'file' ? props.inputClasses.join(' ') : styles.hidden}
                {...(props.type !== 'text' ? {type: props.type} : {type: 'text'})}
                onClick={onButtonClick}
                {...(props.type === 'file' ? {multiple: true} : null)}
            />
        </div>
    );
}

export default Input;
