import IFile from "./FileTypes";

enum FileExtensions {
    PDF = 'pdf',
    PNG = 'png',
    JPEG = 'jpeg',
    JPG = 'jpg',
    GIF = 'gif',
    TXT = 'txt'
}

export class FileHelper {
    static isImage(file: IFile):boolean {
        switch (file.extension.toLowerCase()){
            case FileExtensions.GIF: return true
            case FileExtensions.PNG: return true;
            case FileExtensions.JPG: return true
            case FileExtensions.JPEG: return true;
        }

        return false;
    }

    static isDocument(file: IFile): boolean {
        switch(file.extension.toLowerCase()){
            case FileExtensions.TXT: return true;
            case FileExtensions.PDF: return true;
        }

        return false;
    }

    static isPdf(file: IFile): boolean {
        return FileExtensions.PDF === file.extension.toLowerCase();
    }

    static isOther(file: IFile):boolean
    {
        return !FileHelper.isImage(file) && !FileHelper.isDocument(file);
    }

}

export interface ListOfFiles {
    url: string;
    file: IFile;
}

export default FileExtensions;
