import React from "react";
import IFile from "../Types/FileTypes"
import styles from './FileItem.module.css';
import h from "../Classes/Helper";
import {FileHelper} from "../Types/FileExtensions";
import FileImage from "./FileImage";
import {useNavigate} from "react-router";

interface FileItemProps {
    file: IFile;
}

const FileItem = (props: FileItemProps) => {

    const navigate = useNavigate();

    function iconPath(file: IFile):string {
        if(FileHelper.isPdf(file)) return './svg/pdf.svg';
        if(FileHelper.isImage(file)) return './svg/image.svg';
        return './svg/file.svg';
    }
    return (<>
        <div className={[
            styles.header,
            styles.columns,
            styles.layout,
        ].join(' ')}>
            <div>Filename</div>
            <div>Size</div>
            <div>Priv</div>
            <div>Type</div>
        </div>
        <li
            onClick={()=>navigate(`/files/detail/${props.file.id}`)}
            className={[
            styles.li,
            styles.layout,
            styles.columns
        ].join(' ')}>
            <div>{h.shortenString(props.file.filename)}</div>
            <div>{h.formatBytes(props.file.size)}</div>
            <div>{props.file.private ? 'YES' : 'NO'}</div>
            <div>
                <FileImage src={iconPath(props.file)} size={32}/>
            </div>
        </li>

    </>);
}

export default FileItem;
