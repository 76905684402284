import React from "react";
import Icon from "../components/Icon";

const MainView = () => {
    return (
        <>
            <div className="main-container">
                <Icon
                    imagePath={'./svg/upload.svg'}
                    label={'upload'}
                    path={'/upload'}
                />
                <Icon
                    imagePath={'./svg/gallery.svg'}
                    label={'gallery'}
                    path={'/gallery'}
                />
                <Icon
                    imagePath={'./svg/files.svg'}
                    label={'files'}
                    path={'/files'}
                />
            </div>
        </>
    );
}

export default MainView;
