import React from 'react';

interface IProgressBlockProps {
    width?: number;
    space?: number;
}

const ProgressBlock = (props: IProgressBlockProps) => {
    return (
        <div
            style={
            {
                width: props.width ? props.width : 10,
                background: 'linear-gradient(to bottom, #66ff66, #00bfff)',
                // backgroundColor: 'white',
                margin: `${props.space !== null ? props.space : 2}px`,
                height: `calc(100% ) - ${props.space ? 2*props.space : 4}px`,
            }
        }
        ></div>
    )
};

export default ProgressBlock;
