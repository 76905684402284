import React, {useEffect, useState} from "react";
import Icon from "../components/Icon";
import {useNavigate, useParams} from "react-router";
import security from "../store/security";
import ajax, {ajaxCallback} from "../Classes/Ajax";
import IFile from "../Types/FileTypes";
import {fileResponse} from "./FilesView_old";
import FileDetails from "../components/files/FIleDetails";

const FileDetailsView = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [file, setFile] = useState<IFile>();

    const ajaxCallback: ajaxCallback = {
        callback: (res: Response) => {
            const response :fileResponse =  res as unknown as fileResponse;
            setFile(response.file);
        }
    }

    useEffect(() => {
        if(!security.getJwt()) {
            navigate('/');
        }
    }, [security.getJwt()]);

    useEffect(() => {
        if(id){
            ajax.makeRequest(`/api/file-record/${id}`, security.getJwt(), ajaxCallback);
        }
    }, []);

    return (
        <>
            <Icon
                imagePath={'./../../svg/leftArrow.svg'}
                path={'/files'}
                size={60}
            />
            {file && <FileDetails file={file}/>}
        </>
    );
}

export default FileDetailsView;
