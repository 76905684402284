import React, {useEffect, useState} from "react";
import Icon from "../components/Icon";
import ajax, {ajaxCallback, ajaxFileCallback} from "../Classes/Ajax";
import security from "../store/security";
import IFile, {IFileExtension} from "../Types/FileTypes";
import styles from "./FileView.module.css";
import {useNavigate} from "react-router";
import FileItem from "../components/FileItem";

interface filesResponse {
    data: {
        files: Array<IFile>;
    }
}

const FilesView = () => {
    const navigate = useNavigate();
    const [filesResponse, setFilesResponse] = useState<filesResponse>();
    const [size, setSize] = useState<number>(128);

    useEffect(() => {
        if(!security.getJwt()){
            navigate('/');
        }
    }, [security.getJwt()]);

    useEffect(() => {
        ajax.makeRequest('/api/files', security.getJwt(), listCallback);
    }, []);


    const listCallback: ajaxCallback = {
        callback: afterGetFilesList
    }


    function afterGetFilesList(res: Response):void
    {
        if(res.status && res.status !== 200) return;
        const data = res as unknown as filesResponse
        setFilesResponse(data);
    }

    return (
        <>
            <Icon
                imagePath={'./svg/leftArrow.svg'}
                path={'/'}
                size={60}
            />
            <div className="App">
                <ul
                    className={[
                        styles.flexColumn,
                        styles.noBullet,
                        styles.ul,
                    ].join(' ')}>
                    {filesResponse ? filesResponse.data.files.map((file:IFile, idx) => {
                        return <FileItem key={`file_${file.id}`} file={file}/>
                    }) : null}
                </ul>
            </div>
        </>
    );
}

export default FilesView;
