import security from "../store/security";
import IFile from "../Types/FileTypes";

const baseURL = process.env.REACT_APP_HOST_URL;

export interface ajaxXMLCallback {
    afterAuthorizationFails?: Function;
    callback: (e: ProgressEvent<XMLHttpRequestEventTarget>) => void;
}

export interface ajaxCallback {
    afterAuthorizationFails?: Function;
    callback: (response: Response)  => void;
}

export interface ajaxFileCallback {
    afterAuthorizationFails?: Function;
    callback: (res: Blob, file: IFile) => void;
    file?: IFile;
}
export interface requestOptions  {
    method: string;
    headers: { [key: string]: string };
    body?: string;
}

export interface options {
    method: string;
}

class Ajax {

    static _self:Ajax|null = null;

    static getInstance() {
        if (!Ajax._self) {
            Ajax._self = new Ajax();
        }
        return Ajax._self
    }

    uploadFile(file: File, jwt:string, url: string, ajaxCallback: ajaxXMLCallback): XMLHttpRequest {

        const xhr = new XMLHttpRequest();

        ajaxCallback.afterAuthorizationFails = () => {
            security.setJwt('');
        }

        xhr.addEventListener('load', (e: ProgressEvent<XMLHttpRequestEventTarget>)=>{
            console.log('load', e);
            this.afterAuthorizationFails(ajaxCallback, e)
        }, false);
        xhr.addEventListener('error', (e: ProgressEvent<XMLHttpRequestEventTarget>)=>{
            console.log('error', e);
            this.afterAuthorizationFails(ajaxCallback, e)
        }, false);
        xhr.addEventListener('abort', (e: ProgressEvent<XMLHttpRequestEventTarget>)=>{console.log('abort', e)}, false);

        xhr.upload.addEventListener('progress', ajaxCallback.callback);

        const formData: FormData = new FormData();
        formData.append('file',file);
        xhr.open("POST", url, true);
        xhr.setRequestHeader('Authorization', 'Bearer '+jwt);
        xhr.send(formData);

        return xhr;
    }

    makeRequest(url: string, jwt: string, ajaxCallback: ajaxCallback, options?: options, data?: object): void {

        const requestOptions: requestOptions = {
            method: options?.method ?? "GET",
            headers: {
                ContentType: "application/json",
                Authorization: 'Bearer '+jwt
            },
        }

        if (options && options.method) {
            requestOptions.method = options.method
            if(options.method === 'POST' && data)
            requestOptions.body = JSON.stringify(data)
        }

        ajaxCallback.afterAuthorizationFails = () => {
            security.setJwt('');
        }

        fetch(baseURL + url, requestOptions).then((res:Response) => {
            this.afterAuthorizationFailsAjax(ajaxCallback, res);
            return res.json()
        })
            .then((res: Response) => {
                if(res){
                    ajaxCallback.callback(res);
                }
            }).catch(err => console.log(err));
    }

    makeFileRequest(url: string, jwt: string, ajaxCallback: ajaxFileCallback): void {

        const requestOptions: requestOptions = {
            method: "GET",
            headers: {
                ContentType: "application/json",
                Authorization: 'Bearer '+jwt
            },
        }

        ajaxCallback.afterAuthorizationFails = () => {
            security.setJwt('');
            console.log('fail');
        }

        fetch(baseURL + url, requestOptions).then((res:Response) => {
            this.afterAuthorizationFailsAjax(ajaxCallback, res);
            return res.blob();
        })
            .then((res: Blob) => {
                if(res && ajaxCallback.file){
                    ajaxCallback.callback(res, ajaxCallback.file);
                }
            }).catch(err => console.log(err));
    }



    private afterAuthorizationFails(ajaxCallback: ajaxXMLCallback, e: Event): void {
        if(ajaxCallback.afterAuthorizationFails) {
            if(e.target instanceof XMLHttpRequest && e.target.status === 401){
                ajaxCallback.afterAuthorizationFails();
            }
        }
    }

    private afterAuthorizationFailsAjax(ajaxCallback: ajaxCallback|ajaxFileCallback, res: Response) {
        if(res.status === 401 && ajaxCallback.afterAuthorizationFails){
            ajaxCallback.afterAuthorizationFails();
        }
    }

}

export default Ajax.getInstance();
