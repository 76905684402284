import React, { useState} from 'react';
import loginStyles from './Login.module.css';
import {observer} from "mobx-react"
import security from "./../store/security";
import Input from "../components/Input";

const Login = observer(() => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")

    function onEmailChange(e:React.ChangeEvent<HTMLInputElement>): void {
        setEmail(e.target.value);
    }

    function onPasswordChange(e:React.ChangeEvent<HTMLInputElement>): void {
        setPassword(e.target.value);
    }

    function onConfirm(e: React.MouseEvent<HTMLInputElement>){
        e.preventDefault();
        if (
            password && email
            && process.env.REACT_APP_HOST_URL
            && process.env.REACT_APP_JWT_URL
            && !localStorage.getItem('jwt')
        ) {
            fetch(process.env.REACT_APP_HOST_URL + process.env.REACT_APP_JWT_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: email,
                    password: password
                })
            }).then(res => res.json())
                .then(res => {
                    if(res.token) {
                        security.setJwt(res.token);
                    }
                }).catch(err => {
                    console.log(err);
            });
        }
    }
    return (
        <div className="App">
            <header className="App-header">
                <form className={loginStyles.box}>
                    <Input
                        onChange={onEmailChange}
                        label={'Email:'}
                        wrapperClasses={[loginStyles.wrapper]}
                        labelClasses={[loginStyles.label]}
                        inputClasses={[loginStyles.input]}
                    />
                    <Input
                        onChange={onPasswordChange}
                        label={'Password:'}
                        wrapperClasses={[loginStyles.wrapper]}
                        labelClasses={[loginStyles.label]}
                        inputClasses={[loginStyles.input]}
                        type={'password'}
                    />
                    <Input
                        type={'submit'}
                        label={'Login'}
                        onClick={onConfirm}
                        wrapperClasses={[loginStyles.wrapper]}
                        labelClasses={[loginStyles.label]}
                        inputClasses={[loginStyles.input, 'button']}
                    />
                </form>
            </header>
        </div>
    );
})

export default Login;
