import './App.css';
import {observer} from "mobx-react"
import Login from "./security/Login";
import security from "./store/security";
import MainView from "./views/MainView";

const App = observer(() => {

  return (
       !security.getJwt() ?
           <Login/> :
           <MainView/>
  );
})

export default App;
