import React from "react";
import styles from "./FileList.module.css";
import dayjs from "dayjs";
import Button from "./Button";
import h from "../Classes/Helper";

interface IListProps extends React.HTMLProps<HTMLDivElement> {
    items: Array<File>;
    xhr: XMLHttpRequest|undefined;
    currentTask: number;
    afterCanceled: (taskNumber: number) => void;
}

const FileList = (props: IListProps) => {

    const onCancel = () => {
        props.xhr?.abort();
        props.afterCanceled(props.currentTask)
    }
    return (
        <>
            <ul>
                {props.items.map((file: File, idx: number) => (
                    <li
                        className={styles.item}
                        key={file.name + `_${idx}`}
                    >
                        <span>{idx + 1}.&nbsp;</span>
                        {file.name},
                        <span>size: </span>
                        {h.formatBytes(file.size)}
                        <span>last modified: </span>{dayjs(file.lastModified).fromNow()},
                        <span>on </span>{dayjs(file.lastModified).format('DD(dddd) MMM YY')}.
                        {(idx === 0 && props.xhr) ? (
                            <Button
                                text={'Cancel'}
                                onClick={onCancel}
                            />) : null}
                    </li>
                ))}

            </ul>


        </>

    )
}

export default FileList;
