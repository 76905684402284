import React from "react";
import IFile from "../../Types/FileTypes";
import styles from "./FileDetails.module.css";
import h from "../../Classes/Helper";
import Button from "../Button";
import dayjs from "dayjs";
import ajax, {ajaxCallback, ajaxFileCallback} from "../../Classes/Ajax";
import security from "../../store/security";
import {useNavigate} from "react-router";
import {fileResponse} from "../../views/FilesView_old";

interface FileDetailsProps {
    file: IFile
}

const FileDetails = (props: FileDetailsProps) => {

    const navigate = useNavigate();

    const ajaxCallback: ajaxFileCallback = {
        callback: (res: Blob, file: IFile) => {
            const fileObject = URL.createObjectURL(res);
            const link = document.createElement('a');
            link.href = fileObject;
            link.download = file.filename;
            link.click();
            URL.revokeObjectURL(fileObject);
        },
        file: props.file
    }

    const onDeleteCallback: ajaxCallback = {
        callback: (res: Response) => {
            const data = res as unknown as fileResponse;
            if(data.file) {
                navigate('/files');
            }
        }
    }

    const onDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ajax.makeFileRequest(`/api/file/${props.file.id}`, security.getJwt(), ajaxCallback);
    }

    const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ajax.makeRequest(`/api/files/${props.file.id}`, security.getJwt(), onDeleteCallback, {
            method: 'DELETE'
        });
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.labelRow}>
                    {h.shortenString(props.file.filename, 28)}
                </div>
                <div className={styles.labelRow}>
                    Size: {h.formatBytes(props.file.size)}
                </div>
                <div className={styles.labelRow}>
                    Uploaded: {dayjs(props.file.createdAt).fromNow()}
                </div>

                <div className={styles.flexCenter}>
                    <Button
                        text={'Download'}
                        onClick={onDownload}
                    />
                    <Button
                        text={'Delete'}
                        onClick={onDelete}
                    />
                </div>
            </div>
        </>
    );
}

export default FileDetails;
