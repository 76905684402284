import React, {useEffect, useRef, useState} from "react";
import style from "./Progress.module.css";
import ProgressBlock from "./ProgressBlock";

interface IProgressProps {
    value: number;
    currentTask: number;
    totalTasks?: number;
    onTaskComplete?: (value: number) => void;
}

const Progress = (props: IProgressProps) => {
    const space = 0;
    const totalTasks = props.totalTasks ? props.totalTasks : 1;
    const [width, setWidth] = useState(10);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        if (wrapperRef.current){
            const fw: number = wrapperRef.current.clientWidth;
            console.log(fw, fw/100);
            setWidth((fw/100));
        }
        return () => {
        }
    },[wrapperRef.current]);

    useEffect(() => {
        if(props.value >= 100 && props.onTaskComplete) {
            props.onTaskComplete(props.currentTask);
        }
    }, [props.value]);


    return (
        <>
            {props.value >= 0 ?(<div className={style.wrapper}>
                <div
                    ref={wrapperRef}
                    className={style.progress}
                >
                    {Array.from({length: props.value}, (i, c) => (
                        <ProgressBlock key={c} width={width} space={space}/>
                    ))}
                </div>
                <div className={style.progressStatus}>
                    <div>Tasks {props.currentTask} of total {totalTasks}</div>
                    <div>{props.value} %</div>
                </div>
            </div>) : null}


        </>
    )
};

export default Progress;
