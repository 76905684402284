import App from "../App";
import {createBrowserRouter} from "react-router-dom";
import UploadView from "../views/UploadView";
import FilesView from "../views/FilesView";
import FileDetailsView from "../views/FileDetailsView";
import GalleryView from "../views/GalleryView";

const router =  createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/upload",
        element: <UploadView/>
    },
    {
        path: "/files",
        element: <FilesView/>
    },
    {
        path: "/gallery",
        element: <GalleryView/>
    },
    {
        path: "/files/detail/:id",
        element: <FileDetailsView/>
    }
]);

export default router;
