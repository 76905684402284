class Helper {

    static _self: Helper | null = null;

    private static KB = 1024;
    private static MB = Helper.KB * 1024;
    private static GB = Helper.MB * 1024;

    static getInstance() {
        if (!Helper._self) {
            Helper._self = new Helper();
        }
        return Helper._self
    }

    shortenString(str: string, maxLength: number = 14): string {
        if (str.length <= maxLength) {
            return str;
        }

        const truncated = str.slice(0, maxLength - 3);
        const lastSpaceIndex = truncated.lastIndexOf(' ');

        if (lastSpaceIndex === -1) {
            return truncated + '...';
        }

        return truncated.slice(0, lastSpaceIndex) + '...';
    }

    formatBytes(bytes: number): string {
        if (bytes < Helper.KB) {
            return `${bytes} B`;
        } else if (bytes < Helper.MB) {
            return `${(bytes / Helper.KB).toFixed(1)} KB`;
        } else if (bytes < Helper.GB) {
            return `${(bytes / (Helper.MB)).toFixed(1)} MB`;
        } else {
            return `${(bytes / (Helper.GB)).toFixed(1)} GB`;
        }
    }

}

export default Helper.getInstance();
