import {makeAutoObservable} from "mobx";

class Security {
    jwt:string = '';

    constructor(){
        makeAutoObservable(this);
    }

    setJwt(jwt:string){
        this.jwt = jwt;
        localStorage.setItem('jwt',jwt);
    }

    getJwt(): string {
        const jwt = localStorage.getItem('jwt');
        if (!this.jwt && jwt && jwt !== '') {
            this.jwt = jwt;
        }

        return this.jwt;
    }

}
const security = new Security();
export default security;
