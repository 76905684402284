import React from 'react';
import styles from './GalleryModal.module.css';
import {IThumbnail} from "../../Types/FileTypes";

interface GalleryModalProps {
    url: string;
    thumbnail: IThumbnail;
    onModalClose: () => void;
}

const GalleryModal = (props: GalleryModalProps) => {

    const onModalClose = () => {
        props.onModalClose();
    }

    return (
        <div className={styles.div}>
            <div className={styles.header}>
                <div className={styles.close} onClick={onModalClose}>x</div>
            </div>
            <div className={styles.imageContainer}>
                <img className={styles.img} src={props.url} alt={props.thumbnail.filename}/>
            </div>
        </div>
    );
}

export default GalleryModal;
